.container {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    .video {
        width: 60%;
    }

    .epg-section {
        width: 40%;

        .header {
            display: flex;
            justify-content: space-between;
        }

        .loading {
            width: 100%;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .list {
            margin-top: 10px;
            width: 100%;
            height: 460px;
            overflow: auto;
        }
    }
}

.epg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 2px 10px;
    border-radius: 5px;
    transition: 0.2s;
    background-color: #8080800f;
    cursor: pointer;

    &:hover {
        background-color: #8080801f;
    }

    .date {
        width: 90px;
        font-weight: bold;
    }

    .title {
        width: calc(100% - 125px);
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.current {
        background-color: #75a99c60;

        &:hover {
            background-color: #75a99c80;
        }
    }

    &.live {
        background-color: #53c41a1f;

        &:hover {
            background-color: #53c41a3f;
        }
    }

    &.disabled {
        background-color: #c9c9c9 !important;
        cursor: no-drop;
    }
}
