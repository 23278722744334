.g-title-style {
    font-size: 35px;
    color: #02867d;
}

.transcoding-status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: auto;
    box-shadow: 1px 1px 4px 0px #0000004a;
}

.resolution-block {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.plus-button {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #02867d;
    border-radius: 5px;
    color: #02867d;
    transition: 0.5s;
}

.plus-button:hover {
    background-color: #02867d;
    color: white;
    cursor: pointer;
}

.minus-button {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dc3545;
    border-radius: 5px;
    color: #dc3545;
    transition: 0.5s;
}

.minus-button:hover {
    background-color: #dc3545;
    color: white;
}

.my-loader {
    border: 7px solid #f3f3f3;
    border-top: 7px solid #02867d;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* asdasdasd */
.server-empty {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.online-offline {
    margin-top: 7px !important;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: auto;
}

.table {
    min-width: 1000px;
}

.table th,
.table td {
    padding: 8px 10px;
    vertical-align: middle;
}

.actions-button {
    width: 34px;
    margin: 3px auto;
    height: 100%;
    font-size: 15px;
    transition: 0.3s;
    z-index: 22 !important;
    text-align: center;
}

.actions-button:hover {
    cursor: pointer;
    background-color: #d6d4d4 !important;
}

.actions-block {
    position: absolute;
    width: 200px;
    right: 35px;
    min-height: 60px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
    box-shadow: 0px 0px 7px 2px #bdbdbd;
    border-radius: 5px;
    z-index: 20 !important;
}

.actions-block-empty {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    zoom: 2;
}

/* del */
.action-btn {
    margin-bottom: 5px;
    width: calc(100% - 10px);
    height: 35px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    padding: 5px;
    border-radius: 5px;
    color: #565656;
}

/* del */
.action-btn:hover {
    cursor: pointer;
    background-color: rgba(194, 194, 194, 0.6);
}

/* del */
.action-icon {
    font-size: 15px;
    margin-right: 8px;
}

.xls-button {
    margin-left: 10px;
    color: #656565 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 5px;
    transition: 0.3s;
}

.xls-button:hover {
    cursor: pointer;
    background-color: #e4e0e061;
    box-shadow: 2px 1px 6px #a5a1a161;
}

.epg-list-section {
    margin-top: 20px;
    overflow: auto;
    height: 80vh;
}

.epg-style {
    margin: 2px 0;
    width: 97%;
    padding: 3px 5px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.epg-style:hover,
.epg-style.selected {
    background-color: #dfd9d9;
}

.epg-date {
    font-size: 14px;
    width: 140px;
}

.epg-title {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(70% - 10px);
    text-align: start;
}

.epg-button {
    color: #339af0;
    transition: 0.5s;
}

.epg-button:hover {
    cursor: pointer;
    color: #0a67b4;
}

.disable-button {
    color: #cccccc;
    transition: 0.5s;
}

.video-popup {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #27262680;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: auto; */
}

.video-popup-empty {
    width: 100%;
    height: 100vh;
}

.epg-part {
    width: 50%;
}

.video-section {
    width: 90%;
    /* height: 50%; */
    position: absolute;
    border-radius: 5px;
    background: white;
}

.close-video {
    position: absolute;
    top: -40px;
    left: -15px;
    font-size: 30px;
    color: white;
    z-index: 5;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.close-video:hover {
    cursor: pointer;
    color: #d0cdcd;
}

.res-btn {
    color: #75655b;
    transition: 0.5s;
    font-size: 20px;
}

.res-btn:hover {
    cursor: pointer;
    color: var(--main-active-color);
}

.f-r {
    position: absolute;
    right: 5px;
}

th {
    position: relative;
}

.hover-style {
    transition: 0.3s;
}

.hover-style:hover {
    color: #007bff;
    cursor: pointer;
}

.tfoot-button {
    border-radius: 5px;
    width: 120px;
    text-align: center;
    transition: 0.3s;
}

.tfoot-button:hover {
    background-color: #0000002b;
    cursor: pointer;
}

.current-page {
    font-size: 18px;
    font-weight: bold;
    margin: 0px 10px 0px 10px !important;
}

.table-min-heigth {
    overflow-x: auto;
    /* height: max-content; */
}

Toastify {
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
}

.channel-position-section {
    width: 90%;
    height: 75vh;
    overflow: auto;
}

.channel-position {
    margin-top: 10px;
    width: 100%;
    /* height: 30px; */
    line-height: 30px;
    background-color: #efefef;
    text-align: start;
    padding: 5px 15px;
    border-radius: 5px;
}

.dropBox {
    width: 70% !important;
    margin: auto;
}

.pad {
    padding: 9px 12px !important;
}

/* MINI SERVER */
.mini-header {
    font-size: 25px;
    margin-bottom: 15px;
    margin-top: 30px;
}

.mini-blocks-section {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fill, minmax(290px, 4fr));

    grid-gap: 15px;
}

.mini-server {
    width: 100%;
    height: 230px;
    border-radius: 6px;
    box-shadow: 2px 1px 7px 3px #ccc;
    padding: 11px;
    background-color: white;
    transition: 0.5s;
}

.mini-server:hover {
    cursor: pointer;
    box-shadow: 2px 1px 15px 5px #ccc;
    background-color: rgb(245, 244, 244);
}

.mini-boxes-section {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mini-box {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--main-active-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.mini-big-box {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid var(--main-active-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mini-text {
    color: white;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
}

.info-text {
    color: var(--main-active-color);
}

.mini-plus {
    font-size: 35px;
    transition: 0.5s;
}

.create-mini-server {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffd6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    transition: 0.5s;
}

.create-mini-server:hover {
    background-color: #fffffff6;
    font-size: 50px;
}

.create-mini-server:hover .mini-plus {
    font-size: 50px;
}

/* CardOfDevice */
.card-of-device {
    height: 90px;
    border-radius: 6px;
    box-shadow: 2px 1px 7px 3px #ccc;
    padding: 6px;
    background-color: #2d2d2d;
    /* background: var(--main-active-color); */

    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: flex;
    overflow: hidden;
    position: relative;

    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}

.card-of-device-name {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 20px;
    font-weight: bold;
    color: white;
    padding-left: 10px;
    padding-top: 5px;
    /* padding-right: 10px; */
}

.card-of-device-count {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    /* background: var(--main-active-color); */
    font-family: inherit;
    line-height: 1;
    padding: 6px;
    border-radius: 12%;
}

.soon {
    position: relative;
    font-size: 10px;
    position: absolute;
    background: #dc3545;
    color: #ffffff;
    -webkit-transform: rotate(-48deg);
    transform: rotate(43deg);
    bottom: -26px;
    right: 7px;
    width: 20px;
    height: 76px;
    text-align: center;
    box-shadow: -2px -1px 8px 0px #0000008f;
}

.soon span {
    font-weight: bold;
    transform: rotate(-89deg);
    position: absolute;
    left: -4px;
    top: 26px;
}

#epgSelect .css-1uccc91-singleValue {
    color: #a2a2a2 !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.document-style {
    width: 100%;
    transition: 0.3;
}

.document-style:hover {
    cursor: pointer;
}

#filepond--drop-label-ok619xdgv:hover {
    cursor: pointer;
}

.empty-settings-style {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.transcoding-table {
    line-height: 2.3rem;
}

.setting-block {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.setting-button {
    padding: 8px 18px;
    border-radius: 6px;
    transition: 0.5s;
    color: #565656;
}

.setting-button:hover {
    background-color: var(--main-active-color);
    box-shadow: -1px 3px 9px 1px #7b7b7b;
    color: white;
    cursor: pointer;
}

.setting-icon {
    font-size: 20px;
    margin-right: 5px;
}

.delete-mailing {
    border-radius: 3px;
    transition: 0.5s;
}

.delete-mailing:hover {
    background-color: #d0d0d0;
    cursor: pointer;
    color: #dc3545;
}

.cast-section {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
}

.actor-name {
    margin-top: 8px;
    height: 15%;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.actor-image {
    width: 100%;
    height: 85%;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
    /* margin-bottom: 10px; */
}

.empty-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
}

.actor-galery {
    width: 170px;
    height: 170px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.actor-galery img {
    width: 100%;
    height: 100%;
    border-radius: 5px;

    object-fit: cover;
}

.actor-galleries-section {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    white-space: nowrap;
    width: 100%;
}

._resolution-block {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 40%;
}

.resolution-name {
    min-width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #a567db;
    color: white;
    margin-right: 10px;
}

.added-resolution {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.new-subtitle-values {
    width: 92%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}

.delete-round {
    position: absolute;
    top: 3px;
    right: 3px;
    background: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #737373;
    transition: 0.5s;
}

.delete-round:hover {
    background-color: indianred;
    color: white;
    cursor: pointer;
}

.backdrop {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.collection {
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* box-shadow: 3px 3px 6px #6666663b; */
}

.display-center {
    display: "flex";
    justify-content: "center";
    align-items: "center";
}

.eye-button {
    width: 34px;
    height: 34px;
    color: #808080;
    text-align: center;
    transition: 0.5s;
    margin: auto;
}

.eye-button:hover {
    background-color: #d6d4d4;
    cursor: pointer;
}

.collection-round {
    margin-left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #de3e3b;
}

.custom-icon-size {
    width: 20px;
    height: 20px;
}

.grey-color {
    color: grey;
}

.categorys-content {
    width: 98%;
    min-height: 85%;
    margin: 1%;
}

.color-block {
    width: 100%;
    height: 30px;
}

.react-tabs__tab svg {
    margin-right: 0.3rem;
}

.react-tabs__tab--selected {
    font-weight: 500;
}

.send-em-type-parent .form-group {
    border: 1px solid #e6e6e6;
    padding: 0.6rem 0.5rem;
    background: #f8f9fa;
}

.send-em-type-parent .react-datepicker-wrapper {
    width: 60%;
}

.smtp-on-of-parent input[type="checkbox"].ios8-switch + label {
    margin-left: 1rem;
    margin-top: 0.3rem;
}

.image-btn img {
    width: 12px;
    height: 22px;
}

.soundtrack-style {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    color: gray;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid grey;
}

.soundtrack-style p {
    width: 90%;
    text-align: start;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.trash-button {
    padding: 5px 7px;
    transition: 0.5s;
    border-radius: 3px;
}

.trash-button:hover {
    cursor: pointer;
    color: #dc3545;
    background-color: rgba(128, 128, 128, 0.267);
}

.row-space-between {
    display: flex;
    justify-content: space-between;
}

.my-input-search-block {
    min-height: 70px;
    max-height: 150px;
    overflow: auto;
    width: 100%;
    background: white;
    position: absolute;
    top: 40px;
    border: 1px solid #ced4da;
    box-shadow: 0px 8px 11px 0px #9a9898;
    left: 0;
    border-radius: 5px;
    padding: 5px 7px;
}

.input-search-value {
    margin-top: 5px;
    transition: 0.3s;
    padding: 0 5px;
}

.input-search-value:hover {
    cursor: pointer;
    background: #8080807a;
}

.search-empty-block {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
}

.text-ellipsis {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.add-galery-button {
    width: 200px;
    height: 240px;
    border: 1px solid #dadada;
    display: flex;
    margin-right: 15px;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #bbb8b8;
    transition: 0.2s;
    border-radius: 5px;
    position: relative;
}

.add-galery-button:hover {
    cursor: pointer;
    color: #848181;
    box-shadow: 2px 3px 6px 1px #d9d9d98a;
}

.galery-style {
    width: 200px;
    height: 240px;
    border-radius: 5px;
    margin-right: 15px;
    margin-top: 15px;
    border: 1px solid #dadada;
    /* box-shadow: 2px 3px 6px 1px #3a393975; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.galery-style img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.galery-style:hover .remove-galery-icon {
    opacity: 1;
}

.remove-galery-icon {
    opacity: 0;
    position: absolute;
    top: -15px;
    right: 0;
    width: 20px;
    height: 20px;
    font-size: 24px;
    color: #e56b6f;
    transition: 0.5s;
}

.remove-galery-icon:hover {
    cursor: pointer;
    color: #af0b0b;
}

.center-pagination {
    margin-top: 20px;
    height: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-pagination .pageination-style {
    float: none;
}

.movie-actor {
    width: 220px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 10px;
    box-shadow: 2px 3px 6px 1px #3a393975;
    margin-right: 15px;
    margin-top: 15px;
    position: relative;
}

.ml-1-6 {
    margin-left: 1.6rem;
}

.fs-95 {
    font-size: 0.95rem;
}

table.user-info-table tbody tr:first-child th,
table.user-info-table tbody tr:first-child td {
    border: none;
}

.document-image {
    width: 200px;
    height: 200px;
    border: 1px solid #dee2e6;
    margin-right: 8px;
    padding: 8px;
    cursor: pointer !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #666666;
}

button#test-table-xlsx-button {
    background-color: var(--green);
    color: var(--white);
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.box__parent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.box__header {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: 0.6rem;
}

.box__info {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 600;
}

.filter {
    cursor: pointer;
    font-size: 1.15rem;
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
}

.filter:hover {
    background-color: #efefef;
}

.filter-options {
    /* del */
    border: 1px solid #dee2e6;
    margin-bottom: 1rem;
    padding: 1rem;
}

.chart-line-channel {
    width: 100%;
    background: white;
    height: 50px;
    margin-top: 12px;
    border-radius: 6px;
    box-shadow: 3px 3px 6px 1px #ccc;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chart-line-channel img {
    width: 41px;
    /* margin-top: 4px; */
    padding: 6px;
}

table tfoot tr td {
    border-color: transparent !important;
}

.navbar {
    padding: 9px 15px;
    background: var(--white);
    border: none;
    border-radius: 0;
    background-color: var(--white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    /* position: fixed; */
    /* width: calc(100% - 250px); */
}

/* * * * * * * PAYMENT CONFIG * * * * * * */
input[type="checkbox"].ios8-switch {
    position: absolute;
    margin: 8px 0 0 16px;
    display: none;
}

input[type="checkbox"].ios8-switch + label {
    position: relative;
    line-height: 2em;
    cursor: pointer;
}

input[type="checkbox"].ios8-switch + label:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 40px;
    /* x*5 */
    height: 24px;
    /* x*3 */
    border-radius: 16px;
    /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="checkbox"].ios8-switch + label:after {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    top: 0px;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="checkbox"].ios8-switch + label:hover:after {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="checkbox"].ios8-switch:checked + label:after {
    margin-left: 16px;
}

input[type="checkbox"].ios8-switch:checked + label:before {
    background: #55d069;
}

/* .payment-edit-btn button:disabled,
button[disabled] {
    border: 1px solid #99999985 !important;
    padding: 0.375rem 0.75rem;
    font-weight: normal;
} */

/* * * * * * * SKIN * * * * * * */
.skin-item {
    width: 100%;
    height: 200px;
    padding: 1rem;
    border-radius: 0.25rem;
    pointer-events: none;
}

.skin-item input {
    width: 100%;
    padding: 0.3rem;
    margin-top: 0.2rem;
    border-radius: 0.25rem;
}

.skin-item button {
    padding: 0.4rem 0.6rem;
    margin-top: 0.5rem;
    border-radius: 0.25rem;
}

.skin-live {
    padding: 1.5rem;
    width: 100%;
    border-radius: 0.25rem;
}

.live-prev-input {
    padding: 0.3rem 0.75rem;
    width: 50%;
    border: 2px solid;
    border-radius: 0.25rem;
}

.list-parent {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 205px;
}

.list-item {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 0.25rem;
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
    border: 2px solid transparent;
}

.list-number {
    margin: 0 0.4rem 0 1rem;
}

.list-logo img {
    padding: 0.3rem;
    width: 60px;
    height: 60px;
}

.list-name {
    margin-left: 0.2rem;
}

.success-background {
    width: 50%;
    padding: 1rem;
    border-radius: 0.25rem;
}

.default-button,
.error-button {
    margin-top: 1rem;
    padding: 0.45rem 2rem;
    border-radius: 0.25rem;
    border: 2px solid transparent;
}

/* * * * * * * SIDEBAR * * * * * * */
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background-color: #f8f9fa;
    color: #fff;
    /* transition: all 1s; */
    min-height: 100%;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    background-color: #ffffff;
}

#sidebar.active {
    /* margin-left: -250px; */
}

#sidebar .sidebar-header {
    text-align: center;
    background: #fff;
    border-bottom: 1px solid #e1e1e1;
    box-shadow: 0 0 65px rgba(0, 0, 0, 0.09);
    height: 70px;
}

#sidebar .sidebar-header img {
    /* width: 100%;
    padding: .9rem 2rem;
    background-color: #000; */
    height: 68px;
    padding: 0.5rem 0.5rem;
    background-color: #fff;
}

#sidebar ul.components {
    padding-bottom: 70px;
    height: 100vh;
    overflow: auto;
}

#sidebar ul.components::-webkit-scrollbar {
    background-color: #d0d2d2;
    border-radius: 5px;
    width: 5px;
}

#sidebar ul.components::-webkit-scrollbar-thumb {
    background-color: #343a40;
    border-radius: 5px;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    position: relative;
    font-weight: 500;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 16px;
}

#sidebar ul li button {
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    width: 100%;
    text-align: left;
    position: relative;
    font-weight: 500;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 16px;
}

#sidebar .dropdown-container a {
    padding-left: 45px;
}

#sidebar ul li button:hover {
    color: #000;
}

#sidebar ul li a:hover svg,
#sidebar ul li button:hover svg {
    color: inherit;
}

#sidebar ul li a svg,
#sidebar ul li button svg {
    margin-right: 7px;
    color: #999;
    width: 18px;
    height: 17px;
}

#sidebar ul li a.active svg {
    color: #fff;
}

#sidebar ul li a.active:hover {
    color: #fff;
}

.active {
    /* color: #fff; */
    /* background: var(--main-active-color); */
}

a[data-toggle="collapse"] {
    position: relative;
}

/* * * * * * * SERVER * * * * * * */

.archive-block {
    /* max-width: 550px; */
    height: 450px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 1px 7px 3px #ccc;
    background-color: white;
}

.archive-block-header {
    display: flex;
    justify-content: space-between;
    height: 20%;
}

.archive-block-header-box {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}

.ram-cpu-section {
    height: 43%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ram-cpu-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    font-weight: bold;

    color: white !important;
    border: 4px solid var(--main-active-color);
    box-shadow: 4px 9px 16px 4px #00000085;
    background: var(--main-active-color);
}

.server-white-info {
    color: white;
    font-size: 25px;
    margin-bottom: 5px;
}

.server-white-big {
    color: #b6b6b7;
    font-size: 20px;
    line-height: 0.5;
}

.user-count-block {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4px solid var(--main-active-color);
    box-shadow: 6px 12px 20px 0 #00000094;
    position: relative;
}

.video-cardes-section {
    display: flex;
    flex-direction: column;
    height: 150px;
    overflow: auto;
}

.video-cardes-block {
    width: 190px;
    height: 65px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--main-active-color);
    box-shadow: 0px 3px 7px 0 #00000059;
    position: relative;
}

.video-card-image {
    width: 120px;
    height: 120px;
}

.channel-count-style {
    position: absolute;
    top: -1px;
    left: 10px;
    font-size: 12px;
    color: var(--main-active-color);
}

.channel-count-style:hover {
    cursor: pointer;
}

.archive-block-footer {
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.server-icon {
    font-size: 20px;
    color: #3c3c3c;
    cursor: pointer;
    transition: 0.5s;
}

.server-edit-icon:hover {
    color: #ffc107;
}

.server-remove-icon {
    margin-left: 5px;
}

.server-remove-icon:hover {
    color: red;
}

.server-info-icon:hover {
    color: var(--main-active-color);
}

.archive-text {
    font-size: 15px;
    color: #000000;
    /*text-shadow: 4px 2px 4px #211f1f;*/
}

.text-style {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loader {
    border: 3px solid #f3f3f3;
    /* Light grey */
    border-top: 3px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* * * * * * * P U L S E * * * * * * * */
.server-green-pulse {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    margin-left: 5px;
    background-color: var(--main-active-color);
    box-shadow: 0 0 2px 1px var(--main-active-color);

    -webkit-animation: server-green-pulse 1.2s ease-in-out infinite alternate;
    animation: server-green-pulse 1.2s ease-in-out infinite alternate;
}

@-webkit-keyframes server-green-pulse {
    0% {
        background: transparent;
    }

    50% {
        background: var(--main-active-color);
    }

    100% {
        background: transparent;
    }
}

@keyframes server-green-pulse {
    0% {
        background: transparent;
    }

    50% {
        background: var(--main-active-color);
    }

    100% {
        background: transparent;
    }
}

.gpu-button {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.61);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: var(--main-active-color);
    transition: 0.5s;
    opacity: 0;
}

.gpu-button:hover {
    opacity: 1;
}

.delete-document:hover {
    cursor: pointer;
    color: #a90f1e;
}

.basic-single-logo {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.need-have-server {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.need-have-server div {
    width: 50%;
    text-align: center;
}

.need-have-server p {
    text-align: justify;
}

.refresh-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: auto;
    box-shadow: 1px 1px 4px 0px #0000004a;
    color: white;
    background-color: #dc3545;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
}

.stream-loading {
    margin: auto;
    width: 20px;
    height: 20px;
    box-shadow: 1px 1px 4px 0px #0000004a;
    border-radius: 50%;
    background-color: #0000004a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-style {
    color: var(--main-active-color);
}

.timex-style {
    color: #dc3545;
}

.image-chooser {
    width: 145px;
    min-height: 145px;
    padding: 8px;
    border: 2px solid #eaecef;
    border-radius: 5px;
    transition: 0.3s;
}

.image-chooser:hover {
    cursor: pointer;
    box-shadow: 4px 1px 5px #ebedef;
}

.gr-parent {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
}

.system-required-config {
    background: #f17d7d2e;
}

.disable-input-style {
    background: #cccccc;
}

.reason-style {
    width: 150px;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.now-next-epg {
    margin-top: 10px;
    font-size: 20px;
}
input.commadn-textarea {
    display: block;
    width: 100%;
    border: none;
    padding: 7px;
}

textarea.commadn-textarea {
    display: block;
    resize: none;
    width: 100%;
    border: none;
    padding: 7px;
    height: auto;
    min-height: 146px;
}

.server-btn {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 3px;
    padding-right: 10px;
    border-radius: 6px;
    color: black;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #6c6c7d;
    font-size: 15px;
}

.server-btn:hover {
    text-decoration: underline;
    color: var(--main-active-color);
}

.headers-btn {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-cards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.dashboard-card {
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 1px 9px 0px #00000021;
    padding: 20px;
    text-align: center;
}

.dashboard-card-info {
    width: 70px;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    background-color: var(--main-active-color);
}

.table-select-section {
    /* del */
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
}

.header-row {
    display: flex;
}

.page-style {
    margin-top: 100px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    overscroll-behavior-x: auto;
    background-color: white;
}

@media (max-width: 768px) {
    #sidebar {
        /* margin-left: -250px; */
    }

    #sidebar.active {
        /* margin-left: 0; */
    }

    #sidebarCollapse span {
        display: none;
    }

    .header-row {
        flex-direction: column;
    }
}

.close-menu-button {
    color: black;
    position: absolute;
    right: 10px;
    top: 20px;
    display: none;
}

.slide-wrapper {
    transition: 0.1s;
}

@media only screen and (max-width: 700px) and (min-width: 100px) {
    #sidebar {
        min-width: 100% !important;
        z-index: 10000;
    }

    .collections-header-row {
        display: block !important;
    }

    .wrapper {
        display: flow-root;
        transition: 3s;
    }

    #content {
        display: flow-root;

        /* width: 100%; */
    }

    .slide-wrapper {
        transition: 0s !important;
    }

    #sidebar .sidebar-header {
        text-align: start;
    }

    .close-menu-button {
        display: block;
    }
}

.table-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 10px;
    object-fit: cover;
}

.table-image-popup {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.712);
    z-index: 3000000000000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-image-container {
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.zoom-image {
    min-width: 400px;
    min-height: 400px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.zoom-close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 50px;
    color: #dc3545;
    cursor: pointer;
    transition: 0.3s;
}

.zoom-close:hover {
    color: #c41324 !important;
    font-size: 55px;
}

.media-play-button {
    font-size: 30px;
    color: #14746f;
    cursor: pointer;
}

.player {
    display: -ms-grid;
    display: grid;
    grid-gap: 2rem;
}

@media screen and (min-width: 992px) {
    .player {
        -ms-grid-columns: 2fr 1fr;
        grid-template-columns: 2fr 1fr;
    }
}

.player-display {
    width: 100%;
    height: 300px;
    padding-top: 56.25%;
    position: relative;
}

.player-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 1.5rem;
    background-color: black;
}

.actor-image-and-info {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
}

.actor-info {
    flex: 1;
    max-width: 500px;
    min-width: 300px;
}

.selected_episode-list-items {
    display: flex;
    width: 100%;
    /* max-width: 48rem; */
    /* justify-content: space-between; */
    background-color: white;
    overflow-y: auto;
}

.selected_episode-list-item {
    margin-right: 30px;
    border: 2px solid transparent;
    /* padding: 4px; */
    width: 115px;
    padding: 8px 0;
}

.selected_episode-list-item.active-episode {
    border-color: var(--main-active-color);
    background: white;
    border-radius: 5px;
}

.selected_episode-list-item p {
    font-size: 14px;
    margin-left: 15px !important;
}

.selected_episode-list-item-image {
    width: 75px;
    height: 75px;
    margin-right: 10px;
    border-radius: 10px;
    object-fit: cover;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.selected_tv-show {
    overflow: auto;
}

.selected_tv-show-episodes {
    display: flex;
}

.selected_tv-show-episodes_content {
    margin-right: 5px;
}

.player .element-group {
    padding: 0 1rem;
    height: 100%;
    overflow-y: auto;
}

.player .element-group::-webkit-scrollbar {
    width: 0.3rem;
}

.player .element-group::-webkit-scrollbar-thumb {
    background-color: #96abbb !important;
}

.player .element {
    margin-bottom: 1rem;
}

.player .element-body {
    padding: 0rem 1rem;
    border: 1px solid #96abbb;
    border-radius: 10rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0.3rem;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    cursor: pointer;
}

.player .element-body > * {
    margin: 0.5rem;
}

.player .element-body:hover {
    background-color: var(--main-active-color);
}

.player .element-body:hover .element-text {
    color: #fff;
}

.player .element-body:hover svg path {
    fill: #fff;
}

.player .element-body:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.player .element-title {
    margin-bottom: 0.3rem;
    text-transform: capitalize;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}

.player .element-icon {
    width: 1rem;
}

.player .element-icon svg {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
}

.player .element-icon svg path {
    fill: #421077;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

.player .element-text {
    color: #96abbb;
    text-transform: capitalize;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    pointer-events: none;
}

.player .element-mark {
    width: 1rem;
    margin-left: auto;
}

.player .element-mark svg {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
}

.player .element-mark svg path {
    fill: var(--main-active-color);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

.ratings-block {
    position: absolute;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    bottom: 10px;
    width: 100%;
}

.save-new-collection {
    grid-gap: 5px;
    display: flex;
}

.simple-loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid var(--main-active-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: auto;
}

.small-loading {
    border: 3px solid #f3f3f3;
    border-top: 3px solid var(--main-active-color);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.value-command {
    color: #607d8b;
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    display: inline-block;
}

.basic-info-uploading {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(240px, 4fr));

    grid-gap: 15px;
    margin-bottom: 20px;
    padding: 10px;
}

.basic-uploader {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #eceef0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.basic-uploader img {
    width: 200px;
    border-radius: 10px;
    cursor: pointer;
    margin: auto;
}

.user-icons-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 10px;
}

.user-icon-style {
    position: relative;
}

.user-icon-style:hover .user-icon-action-block {
    opacity: 1;
}

.user-icon-style img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border: 1px solid #999999;
    border-radius: 10px;
}

.user-icon-action-block {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0000004b;
    border-radius: 10px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    transition: 0.2s;
}

.section-center {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.buttons-item {
    width: 120px;
    font-size: 14px;
    cursor: pointer;
    text-align: end;
    padding: 5px 0px 5px 5px;
    color: #007bff;
}

.movie-statistic {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    margin-bottom: 20px;
}

.epg-switch {
    width: 190px;
    display: flex;
    margin-right: 20px;
    padding-top: 10px;
}
