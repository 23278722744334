.chart-component {
    width: 100%;
    min-height: 535px;
    // width: 49%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 0px #69656526;

    .title {
        width: 100%;
        height: 40px;
    }
}