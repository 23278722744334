.page {
    width: 100%;
    height: 100vh;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
        width: 400px;
        background-color: rgba(235, 234, 234, 0.258);
        padding: 40px;
        border-radius: 10px;

        h2 {
            // font-size: 25px;
            margin-bottom: 10px;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
        }

        h5 {
            margin-bottom: 10px;
            text-align: center;
            font-size: 14px;
        }

        .error {
            margin-top: 10px;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content-provider-block {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .wrapper {
            width: max-content;
            padding: 10px;
            cursor: pointer;
            transition: 0.2s;
            border-radius: 5px;

            &:hover {
                background-color: rgba(235, 234, 234, 0.258);
            }
        }
    }
}

.language-selector {
    width: 90px;
    position: absolute;
    top: 7.5px;
    right: 7.5px;
}