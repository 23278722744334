.resolutions {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
        margin: 0;
        width: max-content;
        min-width: 155px;
        color: rgb(58, 141, 235);

        svg {
            color: rgb(58, 141, 235);
        }
    }
}

// .transcoder-terminal-wrapper {
//     width: 100%;
//     height: 87vh;
//     padding: 10px;
//     border: none;
//     background-color: black;
//     overflow-y: scroll;
//     display: inline-block;

//     .terminal-content {
//         color: green;
//         width: 100%;
//         text-wrap: pretty;
//     }

// }