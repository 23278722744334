.language {
    width: 100%;
    position: relative;
    display: inline-block;
}

.drop-down {
    cursor: pointer;
    padding: 10px;
    border: .1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    user-select: none;
    margin-bottom: 5px;
}

.selected {
    width: 100%;
    max-height: 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: .1px solid #ccc;
    border-radius: 4px;

    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    z-index: 1000;

    &>div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}

.options div {
    padding: 10px;
    cursor: pointer;
    max-height: 35px;

}

.options div:hover {
    background-color: #f1f1f1;
}