.messages {
    height: 600px;
    border: var(--border);
    border-radius: 5px;
    background: var(--msger-bg);
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        padding: 10px 15px;
        border-bottom: var(--border);
        background: #f7f7f7;
        color: #666;
        height: 50px;

        .profile-info {
            display: flex;
            align-items: center;

            line-height: 1;
        }

        .last-seen {
            font-size: 12px;
            color: gray;
        }

        .online {
            color: #39b889;
            font-size: 12px;
            font-weight: bold;
        }

        .delete {
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                color: var(--red);
            }
        }
    }

    .list {
        padding: 10px;
        height: 510px;

        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #ddd;
        }
        &::-webkit-scrollbar-thumb {
            background: #bdbdbd;
        }
    }

    .send-message-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 5px;

        border-top: 1px solid #e7e7e7;

        .upload {
            width: 30px;
            text-align: center;
            position: relative;
            cursor: pointer;

            svg {
                width: 20px;
                path {
                    fill: gray;
                }
            }

            input {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }

        textarea {
            max-width: 480px;
            resize: none;
            padding: 5px;
            background-color: transparent;
            color: gray;
            border: none;
        }

        .send {
            color: grey;
            cursor: pointer;
        }
    }
}

.message {
    width: max-content;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
    margin: 10px 0px;

    .image {
        width: 40px;
        height: 40px;
        background-color: #e1dfdf;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .message-block {
        max-width: 320px;
        min-width: 250px;
        background-color: #f7f7f7;
        padding: 5px 10px;
        border-radius: 5px;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 15px;
            }

            span {
                font-size: 12px;
                color: grey;
            }
        }

        .is-seen {
            text-align: end;

            svg {
                height: 20px;
                width: 20px;
            }

            &.is-read {
                svg {
                    path {
                        fill: #39b889;
                    }
                }
            }
        }

        .body {
            margin-top: 5px;
            font-size: 14px;
        }
    }
}

.admin {
    flex-direction: row-reverse;
}

.file_type {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    background-color: #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #666;
}

.send_file_modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
