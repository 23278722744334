.stream-step {
    max-width: 50%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}

.stream-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    gap: 10px;
}

.times {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}