.document {
    width: 230px;
    height: 200px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    img {
        object-fit: cover;
    }

    .container {
        width: 230px;
        height: 160px;
        border: 1px dashed #d9d9d9;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #666666;
        border-radius: 10px;

        &:hover {
            background: rgba(0, 0, 0, 0.02);
        }
    }
}
