@import url(../../app.scss);

.menu {

    min-width: 130px;

    .item {

        padding: 2px 5px !important;

        svg {
            font-size: 15px;
        }

        span {
            color: #00000086;
        }

        .row {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 5px;
        }

    }
}

.locale-component {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 650px;
    flex-shrink: 0;
    margin: 150px auto;
    padding: 30px;
    border-radius: 24px;
    border: 1.202px solid #F8F9FA;
    background: #FFF;

    box-shadow: 0px 5px 24px 0px rgba(215, 215, 215, 0.50);
}

.local-component-icon {
    width: 50px;
    height: 50px;

    &>span>svg {
        width: 50px;
        height: 50px;
        fill: var(--main-active-color);
    }
}

.local-component-head {

    // margin-top: 10px;
    &>span {
        color: #1F1F1F;
        text-align: center;
        font-size: 24px;
        // font-style: normal;
        // font-weight: 500;
        // line-height: normal;
    }
}

.local-component-description {
    &>span {
        display: inline-block;
        color: #464646;
        text-align: center;
        font-size: 18px;
        // font-style: normal;
        // font-weight: 500;
        // line-height: normal;
    }
}

.local-component-button {
    display: flex;
    align-items: center;

    gap: 12px;
    margin-top: 12px;
}