.my-popup-style {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-style {
    width: 48%;
}

.input-box {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.row-style {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.grid-gap-10 {
    grid-gap: 10px;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--white);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
}

.close-popup {
    transition: all 0.4s;
    padding: 0px 10px;
    border-radius: 4px;
}
.close-popup:hover {
    background-color: #dcdcdc;
}

.h-125 {
    height: 125.5px;
}

/* button:disabled {
    border: 2px solid #99999985;
    background-color: #e7e7e754;
    color: #848484;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 600;
} */

.add-user-btn {
    box-sizing: border-box;
    border: 1px solid var(--main-active-color);
    background-color: var(--main-active-color);
    color: var(--white);
    font-weight: 600;
    padding: 6px 13px 4px;
    border-radius: 4px;
    margin-bottom: 15px;
    opacity: 1;
    transition: all 0.3s;
}
.add-user-btn:disabled {
    color: var(--white) !important;
    font-weight: 600 !important;
    padding: 6px 13px 4px !important;
    border-radius: 4px !important;
    margin-bottom: 15px !important;
    opacity: 1 !important;
    transition: all 0.3s !important;
}
.add-user-btn:hover {
    opacity: 0.9;
}
.add-user-btn svg {
    margin-right: 5px;
}
.red {
    color: var(--red);
}

input[type="checkbox"].ios8-switch:checked + label:before {
    background: var(--main-active-color) !important;
}

.add-button-popup {
    box-sizing: border-box;
    border: 2px solid transparent;
    background: var(--main-active-color);
    padding: 5px 10px;
    color: var(--white);
    border-radius: 4px;
    font-weight: 600;
    opacity: 1;
}

.blue-button {
    box-sizing: border-box;
    border: 2px solid transparent;
    border: 2px solid transparent;
    background: var(--site-color);
    padding: 5px 10px;
    color: #fff;
    border-radius: 4px;
    font-weight: 600;
    opacity: 1;
}

.add-button-popup:hover,
.danger-button:hover,
.blue-button:hover {
    opacity: 0.9;
}
.bordered-button:hover {
    background-color: #bfbfbf14;
}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 22;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.popup-empty-section {
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background-color: #0e0e0e7d;
    overflow-y: scroll;
}

.popup-style {
    height: 100%;
    position: relative;
    background-color: white;
    text-align: center;
    z-index: 1000;
    box-shadow: 1px 3px 20px 6px #313030c4;
}
.command {
    background: #ccc;
    padding: 20px;
    color: white;
    font-weight: bold;
    margin-top: 10px;
}
div.constants {
    color: #f44336;
}

.inline-block-element {
    display: inline-block;
    margin-left: 5px;
    margin-right: 6px;
}
.close-button-style {
    background: white;
    margin-top: 10px;
    margin-left: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    box-shadow: -1px 2px 10px 0px #31303075;
    transition: 0.5s;
    font-size: 20px;
    color: #737373;
}

.close-button-style:hover {
    background: #636363;
    color: white;
    cursor: pointer;
}
.close-button-style:hover i {
    color: white;
}

.popup-button-style {
    min-width: 130px;
    height: 40px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: bold;
    color: white;
    transition: 0.5s;
    box-shadow: 0px 1px 3px #7776769e;
    padding: 6px 12px;
}

.popup-button-style:hover {
    box-shadow: 2px 2px 5px #00000069;
    cursor: pointer;
}

.connected-button {
    width: 170px;
    height: 40px;
    border-radius: 10px;
    font-size: 15px;
    background-color: #e2ae13;
    box-shadow: 0px 0px 10px 0px #00000066;
    color: white;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.image-input:hover {
    cursor: pointer;
}

.archive-url-section {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
}

.slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

/* @media only screen and (max-width: 992px) {
    .popup-style {
        width: 100% !important;
    }
} */

@media only screen and (max-width: 700px) and (min-width: 400px) {
    .popup-style {
        /* width: 100% !important; */
    }
}

/* @media only screen and (max-width: 1200px) and (min-width: 700px) {
    .popup-style {
        width: 60% !important;
    }
} */

.grid-row {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.channel-background {
    width: 100%;
    text-align: center !important;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.channel-background-container {
    width: 100%;
    height: 145px;

    border: 1px solid #ebedf0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.channel-background-container:hover {
    cursor: pointer;
    box-shadow: 4px 1px 5px #ebedef;
}

.channel-background img {
    object-fit: cover;
}

.channel-background input {
    margin-top: 20px;
    cursor: pointer;
}

.primary-color-popup {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
