@import url(./buttons.css);

.email-notification-footer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
}

.email-box-block {
    border: 1px solid #e6e6e6;
    padding: 0.6rem 0.5rem;
    background: #f8f9fa;
}

/* .skins-section {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
} */

.servers-section {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
}

.charts-section {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.user-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.info-row {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}

.user-image-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .user-buttons-section {
    width: 35%;
    display: flex;
    flex-direction: column;
} */

.user-block-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.documents-row {
    margin-top: 20px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.logout-size {
    font-size: 15px;
}

#test-video {
    display: none;
}

@media only screen and (max-width: 400px) {
    .charts-section {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .info-row {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}
/* 
@media (max-width: 500px) {
    .servers-section {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }

    .archive-text {
        font-size: 15px !important;
    }

    .ram-cpu-block {
        width: 60px;
        height: 60px;
    }

    .ram-cpu-block p {
        font-size: 14px !important;
    }

    .user-count-block {
        width: 120px;
        height: 120px;
    }

    .archive-block-header {
        display: block;
    }
} */

#user-profile-block {
    transition: 0.2s;
    padding: 0px 10px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

#user-profile-block:hover {
    background-color: #00000017;
}

.upp-icon {
    position: absolute;
    right: 4px;
    top: -24px;
    font-size: 40px;
    color: white;
    z-index: 200;
}

.sortable-item-style {
    z-index: 1000;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* padding: 5px 20px; */
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #e2e2e2;
    visibility: visible !important;
    background: white;
}

.sortable-item-style img {
    width: 100%;
}

.collection-movie {
    margin: 0 !important;
    background-color: white !important;
    /* justify-content: center; */
    /* align-items: center; */
}

.collection-movie img {
    object-fit: cover;
}
