.next-back-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 5px;

    transition: 0.2s;

    &:hover {
        color: gray;
        cursor: pointer;
    }
}

.button-style {
    display: flex;
    align-items: center;

    &:disabled {
        &:hover {
            cursor: not-allowed;
            background-color: #0000000a !important;
        }
    }
}