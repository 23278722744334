.icon-button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-button {
    background-color: #2f8fc0;
    transition: 0.2s;

    &:hover {
        background-color: #2f8fc0 !important;
    }
}
