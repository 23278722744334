.collapse {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

}

.collapse-head {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;

    &::before {
        content: "";
        position: absolute;
        width: 99%;
        height: .1px;
        top: 50%;
        left: 49%;
        background: #e0e0e0;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}


.collapse-name {
    width: auto;
    text-align: start;
    background: #fff;
    // padding: 0 7px;
    padding-left: 10px;
    padding-right: 5px;
}



.collapse-button {
    width: 23px;
    height: 23px;
    text-align: center;
    background: #fff;
    cursor: pointer;
}

.collapse-icon {
    width: inherit;
    height: inherit;
    background-image: url(../../../../../../public/collapse.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    // padding: 0 30px;
    padding: 0 10px;


}


.collapse-content {
    width: 100%;
    transition: .3s linear;
    overflow: hidden;
}

.collapse-child {
    width: 100%;
    overflow: hidden;
    padding: 15px 0;
}