.button-component {
    min-width: 130px;
    height: 40px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: bold;
    color: white;
    transition: 0.5s;
    box-shadow: 0px 1px 3px #7776769e;
    padding: 6px 12px;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button-component:hover {
    box-shadow: 2px 2px 5px #00000069;
    cursor: pointer;
}

.mini-button-component {
    min-width: 40px;
    margin-right: 0 !important;
}

.action-button-component {
    margin-bottom: 5px;
    width: calc(100% - 10px);
    height: 35px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    padding: 5px;
    border-radius: 5px;
    color: #565656;
}

.action-button-component:hover {
    cursor: pointer;
    background-color: rgba(194, 194, 194, 0.6);
}

.action-button-component-footer {
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    transition: 0.3s;
    border-radius: 5px;
    color: #565656;
    padding-left: 5px;
    padding-right: 5px;
}

.action-button-component-footer:hover {
    cursor: pointer;
    background-color: rgba(194, 194, 194, 0.6);
}

.action-button-component-icon {
    font-size: 15px;
    margin-right: 8px;
}

.clear-button {
    background-color: #dc3545;
}

.clear-button:hover {
    background-color: #c42837;
}

.update-button {
    background-color: #e4ab00;
}

.update-button:hover {
    background-color: #cc9a05;
}

.smtp-button {
    background-color: var(--main-active-color);
}

.smtp-button:hover {
    background-color: var(--main-active-color);
}

.send-button {
    background-color: var(--main-active-color);
}

.send-button:hover {
    background-color: var(--main-active-color);
}

.play-button {
    background-color: #277da1;
}

.play-button:hover {
    background-color: #227192;
}

.smtp-button-section {
    display: flex;
    margin-bottom: 20px;
}

.upload-button {
    background-color: #489fb5;
}

.upload-button:hover {
    background-color: #2f7283;
}

.logout-button {
    background-color: #f08080;
}

.logout-button:hover {
    background-color: #e06969;
}

.show-button {
    background-color: #489fb5;
}

.show-button:hover {
    background-color: #2f7283;
}

.payment-history-filter-buttons {
    display: flex;
    align-items: center;
}
