// .chart-wrapper {
//     margin-top: 20px;
//     // width: 49%;
//     width: 100%;

// }



// @media (max-width: 1300px) {
//     .chart-wrapper {
//         width: 100%;
//     }
// }
.chart-wrapper {
    margin-top: 20px;
    width: 49%;

}



@media (max-width: 1300px) {
    .chart-wrapper {
        width: 100%;
    }
}