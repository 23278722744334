.container {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.warrning {
    color: #d46b08;
    background: #fff7e67c;
    border: 1px solid #ffd591;
    padding: 10px 40px;
    text-align: center;
}

.search-from-tmdb {
    display: grid;
    grid-template-columns: 15% 20% 60%;
    grid-gap: 10px;
}
