.container-range-wrap {
    width: 100%;
    position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8px;
    background-color: gree;
}
.container-range {
    width: 96%;
    position: relative;
}

.multi-range-slider__thumb {
    pointer-events: none;
    color: transparent;
    outline: none;
    border: none;
    position: absolute;
    outline: none;
    height: 0;
    width: 7px;
    height: 12px;
    top: -6px;
    border-radius: 1px;
    background-color: #d4d8da;
}
/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
.thumb {
    pointer-events: none;
    position: absolute;
    outline: none;
    height: 0;
}
.thumb--first {
    z-index: 5;
}
.thumb--second {
    z-index: 4;
}
.thumb--tree {
    z-index: 3;
}
.thumb--four {
    z-index: 2;
}
/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    border: none;
    cursor: pointer;
    background-color: #d4d8da;
    box-shadow: 0 0 1px 1px #d4d8da;
    height: 8px;
    width: 5px;
    pointer-events: all;
    position: relative;
}
/* For Firefox browsers */
.thumb::-moz-range-thumb {
    border: none;
    cursor: pointer;
    background-color: #d4d8da;
    box-shadow: 0 0 1px 1px #d4d8da;
    height: 8px;
    width: 5px;
    pointer-events: all;
    position: relative;
}
