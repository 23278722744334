.transcoder-terminal-wrapper {
    width: 100%;
    height: 87vh;
    padding: 10px;
    border: none;
    background-color: black;
    overflow-y: scroll;
    display: inline-block;

    .terminal-content {
        color: green;
        width: 100%;
        text-wrap: pretty;
    }

}