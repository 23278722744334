.header {
    background-color: #fbfdff !important;
    position: fixed;
    width: 100%;
    // height: 100%;
    top: 0;
    transition: 0.5s;
    z-index: 10;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    hr {
        margin: 0;
        background-color: rgba(128, 128, 128, 0.174);
    }
}

.header-item-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.language-selector {

    width: 90px;
    // height: 40px;
    // position: absolute;
    // top: 7.5px;
    // right: 7.5px;
}