.tab {
    width: 400px;

    .title {
        font-size: 18px;
        font-weight: bold;
    }

    .description {
        font-size: 15px;
        margin-bottom: 20px;
    }
}
