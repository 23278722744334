.sidebar {

    background-color: #fbfdff !important;
    z-index: 1;
    height: 100vh;
    border-right: 1px solid #d8d8d87d;
    // overflow: scroll;

    header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .header-image-wrapper {
            // width: 150px;
            // height: ;
            margin: 10px auto;
        }

        img {

            object-fit: contain;
            margin: 10px auto 5px;
            transition: 0.3s;
            // width: 80%;
            height: auto;
        }
    }

    .sidebar-menu {

        height: calc(100vh - 210px);
        overflow: auto;


        &::-webkit-scrollbar {
            width: 1px;
        }

        &::-webkit-scrollbar-track {
            background: #ddd;
        }

        &::-webkit-scrollbar-thumb {
            background: #bdbdbd;
        }



        // li>span {
        //     color: rgb(108, 107, 107);
        // }

        // .ant-menu-item {
        //     color: #000;
        // }



        svg {
            width: 20px;
        }


    }

    .app-version {

        width: 100%;
        margin: 0 auto;
        padding: 0 0 20px;

        &>span {
            display: block;
            text-align: center;
        }
    }
}