.transcoding-drawer {
    .body {
        display: flex;
        justify-content: space-between;
        height: 100%;
        gap: 50px;

        .header {
            font-size: 18px;
            margin-bottom: 5px;
        }

        .resolution-config {
            width: 350px;
            background-color: rgb(240, 239, 239);
            padding: 20px;

            .container {
                display: flex;
                flex-direction: column;

                textarea {
                    resize: none;
                    border: transparent;
                }
            }

            .footer {
                margin-top: 10px;

                .hls {
                    color: rgb(234, 79, 79);

                    input {
                        border: none !important;
                    }
                }
            }
        }
    }
}
