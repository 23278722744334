.statistics-list {
    display: grid;
    grid-gap: 15px;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin-top: 25px;
}

.statistic-card {
    display: flex;
    padding: 20px 15px;
    border-radius: 5px;
    gap: 20px;

    background: rgb(248, 249, 250);
    background: linear-gradient(90deg,
            rgba(248, 249, 250, 1) 0%,
            rgba(233, 236, 239, 0.8758096988795518) 50%,
            rgba(222, 226, 230, 0.691) 100%);
    transition: 0.2s;

    cursor: pointer;

    .title {
        font-size: 16px;
        font-weight: bold;
        color: var(--main-active-color);
    }

    span {
        display: inline-block;
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;

        font-size: 14px;
        font-weight: 500;
        color: grey;
    }

    svg {
        path {
            fill: var(--main-active-color);
        }
    }

    &:hover {
        transform: scale(1.02);

        box-shadow: 0px 0px 7px 0px #69656526;
    }

}