.chart {
    // width: 900px;
    // width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 0px #69656526;
}

.chart-wrapper {
    margin-top: 20px;
    width: 49%;

}



@media (max-width: 1300px) {
    .chart-wrapper {
        width: 100%;
    }
}