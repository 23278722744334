.meta-data-step {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
}

.triler-url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    &>button {
        display: flex;
        align-items: center;
        justify-content: center;

    }
}

.release-audio {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    align-items: center;
}

.ratings {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    img {
        width: 35px !important;
    }
}