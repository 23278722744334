.page {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
        font-size: 20px;
        color: white;
    }
}
