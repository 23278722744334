.conversations-list {
    width: 100%;
    height: 600px;
    background-color: #b2b2b21a;

    .search {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            margin: 0;
        }
    }

    .list {
        height: calc(100% - 60px);
        overflow: auto;
    }
}

.conversation {
    cursor: pointer;
    gap: 8px;
    padding: 5px 10px;
    border-bottom: 1px solid #9e9c9c31;
    position: relative;
    cursor: pointer;
    .profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .image {
        width: 25%;
        position: relative;

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }

        .online {
            width: 10px;
            height: 10px;
            background-color: rgb(20, 179, 20);
            position: absolute;
            bottom: 0px;
            left: -3px;
            border-radius: 50%;
        }
    }

    .info {
        width: 75%;
        display: flex;
        flex-direction: column;
        .name-message-date {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .last-message-date {
                width: 100%;
                text-align: end;
                font-size: 11px;
            }
        }

        p {
            font-size: 14px;
            width: 140px;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1;
            color: rgb(32, 32, 32);
        }

        span {
            font-size: 11px;
            color: rgba(53, 53, 53, 0.685);
            width: 95%;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .message {
            font-size: 14px;
            color: gray;
            width: 95%;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.5;

            svg {
                width: 15px;
                height: 15px;

                path {
                    fill: gray;
                }
            }
        }
    }

    &.active {
        background: rgba(128, 128, 128, 0.195);
    }

    .not-read-count {
        position: absolute;
        background-color: #e23c6e;
        right: 5px;
        bottom: 5px;

        width: 20px;
        height: 20px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 12px;
    }
}
