.server {
    width: 400px;
    background-color: white;
    box-shadow: 0px 2px 5px 0px #9492923d;
    border-radius: 10px;

    padding: 20px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        p {
            font-size: 15px;
            font-weight: bold;
        }
    }
}

.storage {
    margin-top: 5px;
    display: flex;
    // align-items: center;
    align-items: baseline;
    gap: 5px;

    .title {
        color: rgb(111, 111, 111);
        font-weight: bold;
    }

    p {
        color: gray;
    }

    .percent {
        color: rgb(111, 111, 111);
        font-weight: bold;
    }
}

.server-info {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ram-cpu {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: var(--main-active-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            font-size: 20px;
            margin-bottom: 0px;
            font-weight: bold;
            color: rgb(211, 211, 211);
        }

        p {
            color: rgb(191, 191, 191);
            font-weight: bold;
        }
    }

    .users {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: 1px solid var(--main-active-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.2s;

        p {
            color: var(--main-active-color);
            font-size: 24px;
            font-weight: bold;
        }

        svg {
            width: 60px;
        }

        &:hover {
            // cursor: pointer;
            transform: scale(1.1);
            box-shadow: 0px 2px 5px 5px #9492923d;
        }
    }
}

.footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .connect {
        display: flex;
    }

    span {
        font-weight: bold;
        color: var(--main-active-color);
    }
}

.logs {
    margin-top: 20px;
    width: 100%;
    height: 90%;
    background-color: black;
    padding: 20px;
    overflow: auto;
    scroll-behavior: smooth;

    .log {
        color: green;
    }
}

.gpu-name {
    width: 100%;
    height: 25px;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.single-gpu {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid var(--main-active-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    position: relative;

    .temperature {
        font-size: 18px;
        color: #737373;
        display: flex;
        gap: 7px;
    }

    .memory {
        font-size: 16px;
    }

    .mib {
        font-size: 13px;
    }
}

.gpu-button {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.61);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: var(--main-active-color);
    transition: 0.5s;
    opacity: 0;

    &:hover {
        opacity: 1;
    }
}

.gpu-list {
    display: flex;
    flex-direction: column;
    height: 150px;
    width: 160px;
    overflow-x: auto;
    gap: 5px;

    .gpu {
        height: 90px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--main-active-color);
        position: relative;

        .temperature {
            font-size: 12px;
            color: #737373;
            display: flex;
            gap: 3px;
        }

        .memory {
            font-size: 12px;
        }

        .mib {
            font-size: 12px;
        }
    }
}