.media-page {
    /* min-height: 150vh; */
    /* padding-bottom: 100px; */
}

.header-section {
    margin-top: 20px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 20px;
}

.image-and-backdrop {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    /* grid-template-columns: repeat(auto-fit, minmax(230px, 1fr)); */
    grid-template-columns: 0.8fr 5fr;
}

.movie-poster-style {
    height: 340px !important;
}

.poster-block {
    width: 100%;
    min-width: 100px;
}

.row-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.media-poster-block {
    position: relative;
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #adb5bd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-image {
    top: 4%;
    right: 7%;
    /* right: 74%; */
    /* right: 5px; */
    position: absolute;
    padding: 5px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    transition: 0.2s;
    z-index: 10;
    cursor: pointer;
}

.remove-image:hover {
    background-color: rgb(234, 233, 233);
    color: #8c2f39;
}

.media-poster {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.backdrop-block {
    margin-top: 10px;
    width: 100%;
    min-width: 200px;
    /* width: calc(100% - 170px); */
}

.backdrop-style {
    width: 100%;
    height: 150px;
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #adb5bd;
    margin-bottom: 10px;
}

.backdrop-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.backdrop-initial-image {
    width: 40px;
    /* height: 100px; */
}

.rating-section {
    display: grid;
    grid-gap: 1rem;

    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.rating-block {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 5px;
}

.rating-block img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.inpust-section {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.inputs-section {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.input-section-row {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-bottom: 10px;
}

.into-section-row {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}

.input-section-row .form-group {
    margin: 0;
}

.section-style {
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    margin-bottom: 20px;
}

.block-style {
    width: 100%;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    /* overflow: auto; */
}

.image-title {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.image-label {
    display: flex;
}

.image-label span {
    margin-left: 10px;
}

.radio-button-style {
    display: flex;
    align-items: center;
}

.radio-button-style input {
    margin-right: 10px;
}

.media-trailer-section {
    margin-top: 10px;
    width: 100%;
    height: 100px;
}

.media-trailer-upload-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trailer-progress {
    margin-top: 10px;
    width: 100%;
    height: 23px;
    background-color: #6c757d;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-size: 12px;
    color: white;
}

.trailer-progress .percent {
    background-color: #2a9d8f;
    border-radius: 20px;

    /* width: 0px; */
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.started-transcode {
    margin-top: 10px;
    color: #2a9d8f;
    font-weight: bold;
}

.trailer-error-text {
    margin-top: 10px;
    color: #8c2f39;
}
