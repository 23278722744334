.playlist-form {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.statistics {
    width: 350px;
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
        width: 60px;
        font-size: 16px;

        font-weight: bold;
    }

    p {
        color: gray;

        span {
            font-weight: bold;
        }
    }
}
