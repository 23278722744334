.search-container {
    width: 100%;
    max-height: 550px;
    overflow: auto;
}

.movie-channel {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-bottom: 1px solid rgba(194, 194, 194, 0.314);
    transition: 0.2s;
    padding: 5px 0;
    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 230px;
        margin: 0 !important;
    }

    .name {
        font-weight: bold;
    }

    &:hover {
        background-color: rgba(191, 189, 189, 0.213);
    }
}
