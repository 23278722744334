.profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 0px 10px;
    transition: 0.3s;
    &:hover {
        background-color: rgba(215, 215, 215, 0.195);
    }
    img {
        width: 40px;
    }
}
