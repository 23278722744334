.tab-header {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
        color: gray;
        width: 30px;
    }

    p {
        font-size: 18px;
    }
}
