.pricing {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.pricing-page-header {
    position: fixed;
    top: 13px;
    left: 50%;
    display: flex;
    align-items: center;
    width: 98%;
    height: 74px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #fff;
    transform: translateX(-50%);
    z-index: 1;
}

.backward {
    display: flex;
    align-items: center;
    margin-left: 20px;

    &>a {

        color: #0F87B2;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        display: flex;
        align-items: center;
        gap: 15px;
    }
}

.pricing-head {
    display: inline-block;
    text-align: center;
    margin: 90px auto 10px;

    color: #1F1F1F;
    text-align: center;
    font-size: 37.241px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pricing-description {
    display: inline-block;
    width: 35%;
    margin: 19px auto;
    color: #464646;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.result {
    width: 100%;
    margin: 0 auto;
}

.pricing-card-wrapper {

    width: 100%;
    min-height: 650px;
    margin: 80px auto;
    border-radius: 15.616px;
    background: #FFF;
    box-shadow: 0px 3.904px 31.232px 0px rgba(20, 16, 74, 0.15);
    padding: 25px 35px;
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
    overflow-y: scroll;


    gap: 15px;
}

.pricing-card {
    min-width: 380px;
    // margin: 0 auto;
    border-radius: 15.616px;
    border: 0.887px solid #E6E8F5;
    background: #FFF;
    padding: 15px;
}

.pricing-card-active {

    min-width: 290px;

    position: relative;
    top: 15px;
    border-radius: 15.616px;
    border: 0.887px solid #E6E8F5;
    background: #FFF;
    padding: 25px;
    z-index: 0;

    &::after {
        content: "Your current plan";
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;
        height: 41px;
        padding-top: 10px;
        flex-shrink: 0;
        border-radius: 15.62px 15.62px 0px 0px;
        background: linear-gradient(97deg, #6DC3ED 5.73%, #2692C6 96.56%);
        text-align: center;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }


    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 15.616px 15.616px;
        border: 2px solid #62BCE7;
        // background: #FFF;
    }
}

.currency {
    & .currency-value {
        color: #374557;
        font-size: 31.922px;
        font-style: normal;
        font-weight: 700;
        line-height: 40.789px;

        &>span {
            color: #374557;
            font-size: 15.074px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }
    }

    &>span {
        color: #374557;
        font-size: 15.074px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.bandwidth,
.storage-size {
    color: #0F87B2;
    font-size: 16.848px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &>span {
        color: #374557;
    }
}


.features-list {
    margin-top: 15px;
}

.features-item {

    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;

}

.features {
    color: #374557;
    font-size: 17px;
    font-style: normal;
    font-weight: 275;
    line-height: 30.789px;
}

.pricing-button-wrapper {
    width: 100%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-button {
    border-radius: 20px;
    background: radial-gradient(59.15% 59.15% at 47.02% 50%, rgba(221, 221, 221, 0.00) 12.55%, rgba(0, 99, 247, 0.13) 100%), linear-gradient(140deg, rgba(33, 179, 232, 0.80) -11.91%, #0F87B2 100%);
    box-shadow: 1px -1px 1px 0px rgba(8, 12, 13, 0.17) inset, 1px 1px 2px 1px rgba(255, 255, 255, 0.33) inset;
    text-shadow: 0px 1px 1.9px rgba(15, 15, 15, 0.22);
    padding: 15px 54px;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: .3s;

    &:hover {
        transition: .3s;

        transform: scale(1.02);
    }


}

.pricing-button-disable {
    border-radius: 20px;
    background: radial-gradient(59.15% 59.15% at 47.02% 50%, rgba(221, 221, 221, 0) 12.55%, rgba(0, 99, 247, 0.13) 100%), linear-gradient(140deg, rgb(239 239 239 / 80%) -11.91%, #bed1d8 100%);
    box-shadow: 1px -1px 1px 0px rgba(8, 12, 13, 0.17) inset, 1px 1px 2px 1px rgba(255, 255, 255, 0.33) inset;
    text-shadow: 0px 1px 1.9px rgba(15, 15, 15, 0.22);
    padding: 15px 54px;
    color: inherit;
}




@media(min-width:1920px) {
    .pricing-card {
        min-width: 350px;

    }

    .pricing-card-active {
        min-width: 350px;

    }
}