@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,800,900|Open+Sans:400,400i,600,700,700i,800");
@import url("./Reset.css");

@import "./css/global.css";
@import "./css/popups.css";
@import "./css/appStyle.css";
@import "./css/medias.css";

/*TEST STYLES*/
.logs {
  width: 100%;
  padding: 2%;
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  background: black;
  color: green;
}
.logs pre {
  color: green;
}

#root {
  overflow-y: hidden;
}
/*TEST STYLES*/
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

@-webkit-keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
@-moz-keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
.chart-heading h4 {
  display: inline-block;
  font-size: 21px;
}
.chart-icon {
  font-size: 30px;
  display: inline-block;
  color: white;
  margin-right: 8px;
}

:root {
  --box-shadow: 0 0 65px rgba(0, 0, 0, 0.09);
  --background: #c1c1c11a;
  --site-color: #007aff;
  --input-background: #e6e6e6;
  --buton-hover: #238dff;
  --white: #fff;
  --green: #28a745;
  --red: #ff4d4f;
  /*--main-active-color: #00857c;*/
  --main-active-color: black;
  /* #ec6814; */
  --hover: black;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  background-color: #f5f2f226;
}
.pointer-events {
  pointer-events: none;
}

.d-flex {
  display: flex;
}

/*---------------------------------------------*/
a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: initial;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h5,
h6 {
  color: #666666;
  margin: 0px;
}
h4 {
  margin: 0;
}

p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
}

textarea {
  outline: none;
  border: none;
}

input::-webkit-input-placeholder {
  color: #8f8fa1;
}
input:-moz-placeholder {
  color: #8f8fa1;
}
input::-moz-placeholder {
  color: #8f8fa1;
}
input:-ms-input-placeholder {
  color: #8f8fa1;
}

textarea::-webkit-input-placeholder {
  color: #8f8fa1;
}
textarea:-moz-placeholder {
  color: #8f8fa1;
}
textarea::-moz-placeholder {
  color: #8f8fa1;
}
textarea:-ms-input-placeholder {
  color: #8f8fa1;
}

label {
  display: block;
  margin: 0;
  color: #666666;
}

.input-validation {
  position: absolute;
  background: #fff;
  top: 21%;
  right: 6%;
  text-transform: capitalize;
  font-size: 13.5px;
  font-weight: 500;
  padding: 0 2px;
}
.red {
  color: #ff0055;
}
.green {
  color: #28a745;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/* Input */
.input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input:disabled {
  border-color: #0000000d;
}
.input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #007aff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
.textarea {
  display: block;
  width: 100%;
  height: 160px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
}
.textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #007aff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

/* Checkbox */
/* The container */
.checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #d4dadf;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkbox-label:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-label input:checked ~ .checkmark {
  background-color: #28a745;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-label .checkmark:after {
  left: 8px;
  top: 3px;
  width: 10px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* loading spinner */
.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2em solid white;
  border-right-color: transparent;
}

.animation-bckground {
  -webkit-background-size: 30px 30px;
  -moz-background-size: 30px 30px;
  background-size: 30px 30px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -ms-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: animate-stripes 0.7s linear infinite;
  -moz-animation: animate-stripes 0.7s linear infinite;
}

.btn {
  cursor: pointer;
}

.archive-row {
  color: #ff0000;
  background-color: rgba(244, 211, 211, 0.37);
}

thead {
  background: #f8f9fa;
}

tbody tr th {
  font-weight: unset;
}

thead tr th {
  font-size: 12px;
}

tbody tr td {
  font-size: 12px;
}

th img {
  height: 38px;
}
.txtooltip {
  position: relative;
  display: inline-block;
}

.txtooltip .txtooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #212529;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 40%;
  margin-left: -100px;
  font-size: 0.8rem;
}

.txtooltip .txtooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.txtooltip:hover .txtooltiptext {
  visibility: visible;
}

.itooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.h120 {
  height: 120px;
}

.itooltip .itooltiptext {
  visibility: hidden;
  width: 65px;
  background-color: #3e4042;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -31px;
  font-size: 0.8rem;
}

.itooltip .itooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.itooltip:hover .itooltiptext {
  visibility: visible;
}

/* switch  button */
.switch {
  transform: scale(0.9);
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3da745;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3da745;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.border-gray {
  border: 1.5px solid #ced4da !important;
}

.channels-content {
  width: 98%;
  min-height: 85%;
  margin: 1%;
}

.tariffs-content {
  width: 98%;
  min-height: 85%;
  margin: 1%;
}

.admin-content {
  width: 98%;
  min-height: 85%;
  margin: 1%;
}

.equal-table tr th,
.equal-table tr td {
  width: 50%;
}

/* #content {
  width: calc(100% - 250px);
} */

.norclass {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
label.checkbox-label.m-0 {
  display: block !important;
  margin-right: 10px !important;
}

th {
  text-align: center;
}

tr {
  text-align: center;
}

.image-style {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.my-button {
  font-size: 1rem;
  color: white;
  font-weight: 400;
  text-align: center;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.my-button:hover {
  box-shadow: 2px 2px 5px #00000069;
}
.mt_12 {
  margin: 12px 0px;
}
.save-button,
.add-button {
  background-color: var(--main-active-color);
}

/* .save-button:hover,
.add-button:hover {
    background-color: var(--hover);
} */

.edit-button {
  background-color: #f78f27;
}

.edit-button:hover {
  background-color: #c76e18;
}

.remove-button {
  background-color: #dc3545;
}

.remove-button:hover {
  background-color: #c42837;
}

.archive-button {
  background-color: #135863;
}

.archive-button:hover {
  background-color: #0e4752;
}

.restore-button {
  background-color: #2890fc;
}

.restore-button:hover {
  background-color: #007bff;
}

.start-button {
  background-color: #219ebc;
}

.start-button {
  background-color: #0c7d9a;
}

.stop-button {
  background-color: #b7b7a4;
}

.stop-button {
  background-color: rgb(164, 167, 168);
}

.restart-button:hover {
  background-color: #096880;
}

.info-button {
  background-color: #007aff;
}

.pause-button {
  background-color: #85898e;
}

.fetch-button {
  background-color: #00b4d8;
}
.fetch-button {
  background-color: #00b4d8;
}

.folder-button {
  color: #f78f27 !important;
  font-size: 25px !important;
  box-shadow: none !important;
}

.folder-button:hover {
  color: #c76e18 !important;
}

.cursor_pointer {
  transition: 0.5s;
}

.cursor_pointer:hover {
  cursor: pointer;
}
.react-datepicker__input-container input {
  width: 100%;
}

.table-row-image {
  background: #2f2f2f;
  padding: 5px;
  width: 50px;
  margin: 2px auto;
  border-radius: 6px;
}

td {
  text-align: center;
}
.top-icon {
  font-size: 30px;
  margin-left: 9px;
  color: var(--main-active-color);
}
.my-card-block {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: white;
  font-size: 25px;
  padding: 11px;
  border-radius: 6px;
  box-shadow: 2px 1px 7px 3px #ccc;
  transition: 0.5s;
}

.my-card-block:hover {
  cursor: pointer;
  box-shadow: 2px 1px 15px 5px #ccc;
  background-color: rgb(245, 244, 244);
}

.block-text {
  font-size: 20px;
  font-weight: bold;
  color: #868585;
  position: absolute;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 12px;
}

.my-card-block .count {
  text-align: center;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  min-width: 50px;
  background: var(--main-active-color);
  font-family: inherit;
  line-height: 1;
  padding: 6px;
  border-radius: 12%;
  box-shadow: -5px 4px 4px 1px #ccc;
}
.chart {
  margin: 0;
  border-radius: 8px;
}

.chart-shadow {
  box-shadow: 2px 1px 7px 3px #ccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.chart .chart-heading {
  background: var(--main-active-color);
  padding: 12px;
  color: white;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.chart-parent {
  background: white;
  padding: 13px;
}

.Toastify__toast--success {
  background: var(--main-active-color);
}

.sub-menu {
  max-height: 0px;
  overflow: hidden;
  height: auto !important;
  transition: 0.3s;
}

.active-sub-menu {
  max-height: 300px;
}

.up-down {
  float: right;
  transition: 0.5s;
}

.sub-icon {
  margin-left: 30px;
}
thead {
  background-color: #f1f1f1 !important;
  color: #656565 !important;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  -ms-user-select: none;
  user-select: none;
  /* box-shadow: 0px 5px 12px 0 #808080b0; */
}

.table-footer {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

tbody tr:hover {
  /* box-shadow: 0 0 11px 0px #dcdcdc;
    background: white;
    transition: all ease 0.2s; */
}
tfoot {
  background: #f1f1f1 !important;
}
/*Add to right css file*/

.pageination-style {
  width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: #666666;
  margin-right: 10px;
  margin-bottom: 30px;
}

.tfoot-button:hover {
  background-color: #d6d6d6;
  cursor: pointer;
  box-shadow: inset 0 0 3px 0px #847373;
}

#logout {
  color: #dc4c4c;
}

.copy-btn {
  margin-left: 10px;
  color: #747474;
  transition: 0.3s;
}

.copy-btn:hover {
  color: #383636;
  cursor: pointer;
}

.limit-button {
  width: 100px;
}

.gpu-info {
  transition: 0.5s;
}

.gpu-info:hover {
  cursor: pointer;
  background-color: rgb(243, 241, 241);
  border: 4px solid #007aff;
  box-shadow: 2px 3px 20px 13px #00000070;
}

.text-loader {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 10px;
  background-color: red;
}
#loader-container {
  right: 50px;
  display: none;
  position: absolute;
  top: 10px;
}

.status-style {
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
}

.status-style.start {
  background: var(--main-active-color);
}

.status-style.start:hover {
  background: #04665f;
}

.status-style.play {
  color: #2890fc;
}

.status-style.play:hover {
  color: #1b78db;
  background: #288efc27;
}

.status-style.error {
  background: #dc3545;
}

.status-style.error:hover {
  background: #c42b3a;
}

.status-style-progress {
  cursor: pointer;
  position: relative;
}

.status-style-progress:hover .status-progress-block {
  display: block;
}

.status-progress-block {
  display: none;
  position: absolute;
  top: 16px;
  right: 60px;
  width: 100px;
  height: 100px;
  background: white;
  padding: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.checkbox-label input:checked ~ .checkmark {
  background-color: var(--main-active-color);
}

#actions-loading-style {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffffc2;
  width: 100%;
  height: 100vh;
  /* z-index: 25; */
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 9999;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 4px !important;
  border: 1px solid #ced4da !important;
  color: #52585f !important;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: var(--main-active-color);
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.command-line {
  padding-left: 20px;
  margin: 5px;
}
.command-input {
  border-radius: 8px;
  padding-left: 12px;
  border: none;
  margin-right: 6px;
  font-size: 13px;
  font-weight: bold;
  color: #4a4646;
}
.command-edit-mode {
  width: 47%;
  background: #e8e8e8;
  padding: 6px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
}
#category-table {
  overflow: hidden;
}
.header {
  text-align: center;
  background: #009688;
  padding: 10px;
}
.header-name {
  color: white;
}
button.transcoding-btn {
  background: green;
  width: 25px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.text-width-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.pouse-btn {
  color: #ca5151 !important;
}

.play-btn {
  color: var(--main-active-color) !important;
}

.menu-icon {
  padding: 5px 10px;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.menu-icon:hover {
  background: #0000001c;
  color: var(--main-active-color);
}
.df-sb {
  display: flex;
  gap: 5px;
}
.table-pagination {
  display: flex;
  align-items: center;
}
.pagination-pages {
  position: absolute;
  width: max-content;
  right: 14%;
  height: 40px;
}
.pagination-pages ul {
  gap: 10px;
  font-size: 20px;
}
.active-page {
  color: black;
}
.active-page a {
  font-size: 20px;
}
.footer-tb {
  width: max-content;
  position: absolute;
}
.footer-tb button {
  color: #656565;
  margin-right: 20px;
  font-size: 13px;
}
.df-baseline {
  display: flex;
  align-items: baseline;
}
.limit-select {
  height: 230px;
  margin-top: 4px;
}
.limit-select label {
  margin-bottom: 0;
}
.limit-select .form-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -5px;
}
.limit-select .form-group div div {
  border-color: transparent !important ;
  border-color: transparent;
  box-shadow: 0 0 0 1px transparent;
}
.limit-select .form-group div div div {
  width: 40px;
}
.limit-select .form-group div div:hover {
  border-color: transparent;
}

.min-add {
  border: 1px solid #ced4db;
  padding: 7px;
  height: 35px;
  background: #5ca53e;
  color: #fff;
  transition: 1s ease;
}
.min-add:focus {
  color: green;
  background: #fff;
}
.min-add:hover {
  color: green;
  background: #fff;
}
.mins-btns {
  display: flex;
  margin-top: 20px;
  height: 35px;
  gap: 5px;
  cursor: pointer;
}
.mins-btns .green {
  background: #fff;
  color: #5ca53e;
}
.mins-btns .red {
  background: #fff;
  color: red;
}
.viewed-channels-head {
  display: flex;
  justify-content: space-between;
}
.viewed-channels-head .filters-list {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-top: 25px;
}
.viewed-channels-head .css-g1d714-ValueContainer {
  width: 100px;
}
.viewed-channels-head .css-yk16xz-control {
  height: 35px !important;
  min-height: 0px !important;
}
.short-text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
}

.transcode-popup-tab {
  display: flex;
  margin-top: 10px;
}
.transcode-popup-tab .form-group {
  width: 100%;
}
.transcode-popup-tab .react-tabs__tab-list {
  display: flex;
}
.transcode-popup-tab .react-tabs {
  width: 100%;
}
.transcode-popup-tab .react-tabs__tab-panel {
  text-align: start;
}
.head-folders {
  text-align: start;
  position: fixed;
  height: 130px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}

.wrap-folders-list {
  height: 550px;
  margin-top: 140px;
  overflow: hidden auto;
}
.folders-list {
  display: grid;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}
.folders-list button {
  width: 100px;
  border: 1px solid transparent;
}
.folders-list .short-text {
  width: 90px;
}
/* .folders-actions-btns .actions-btns button:disabled,
button[disabled] svg {
    fill: #666 !important;
} */
.folders-list .txtooltip .txtooltiptext {
  margin-left: -60px;
}
.folders-list button:focus svg {
  fill: #f4c47f;
}

.folders-list .file:focus svg {
  fill: #68acf5;
}
.folders-list .file {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: start;
}
#uploadToast {
  position: fixed;
  left: 73%;
  top: 10px;
  z-index: 35;
}
.short-text-uploading {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
}
#uploadToast .toast-body {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}
#uploadToast .toast-body svg {
  height: 30px;
  color: #007bff;
  width: 23px;
}

#uploadToast .toast-body button svg {
  height: 20px;
  color: rgb(246, 70, 70);
  width: 20px;
  margin-left: 10px;
}
#uploadToast .body-toast-texts {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.folders-actions-btns {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  align-items: flex-end;
}
.fix-serie-wrap {
  width: inherit;
  overflow: auto;
}
.input-ischack {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
#searchedChannels input:disabled {
  background: #dddddd;
}

.most-viewed-not-found {
  color: grey;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.media-names .short-text {
  font-weight: 600;
  font-size: 17px;
}
.channels-tb td {
  padding: 8px 10px !important;
}
.channel-switch .enable-disable-block {
  margin-top: -12px;
}
.disabledPayment {
  cursor: no-drop;
  color: grey;
}

video {
  position: relative;
  object-fit: contain;
  max-height: 500px;
  height: -webkit-fill-available;
}
.intro-times-inps {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.intro-times-inps span {
  /* width: 150px; */
  display: inline-block;
}
.intro-times-inps input {
  /* min-width: 150px; */
  width: 100%;
}
.df-sb-quiz {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.checkbox-isCorrect {
  width: 25px;
  height: 25px;
  margin: 12px 5px;
  cursor: pointer;
}
/* .is-correct{
    display: flex;
    align-items: center;
    margin-top: -17px;
} */
.answer-number {
  width: 100px;
}
.answer-content {
  width: 420px;
}

.hls-input {
  width: 50px;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 1px solid black;
}

.not-read-messages-count {
  position: absolute;
  top: 8px;
  right: 0px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(237, 75, 75, 0.963);
  color: white;
}
.ant-upload-list ant-upload-list-text{
  width: 130px !important;
}