.devices-list {
    margin-top: 10px;
    display: grid;
    grid-gap: 15px;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.device-card {
    // height: 130px;
    border-radius: 6px;
    box-shadow: 2px 1px 7px 3px #ccccccb3;
    padding: 6px;
    background-color: #2d2d2d;

    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: flex;
    overflow: hidden;
    position: relative;
    background: rgb(73, 80, 87);
    background: linear-gradient(90deg,
            rgba(73, 80, 87, 1) 0%,
            rgba(52, 58, 64, 1) 50%,
            rgba(33, 37, 41, 1) 100%);
    align-items: center;
    justify-content: space-evenly;

    .name {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        padding-top: 5px;

        p {
            color: white;
        }
    }

    .count {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        /* background: var(--main-active-color); */
        font-family: inherit;
        line-height: 1;
        padding: 6px;
        border-radius: 12%;
    }

    .soon {
        position: relative;
        font-size: 10px;
        position: absolute;
        background: #dc3545;
        color: #ffffff;
        -webkit-transform: rotate(-48deg);
        transform: rotate(43deg);
        bottom: -26px;
        right: 7px;
        width: 20px;
        height: 76px;
        text-align: center;
        box-shadow: -2px -1px 8px 0px #0000008f;

        span {
            font-weight: bold;
            transform: rotate(-89deg);
            position: absolute;
            left: -4px;
            top: 26px;
        }
    }
}