.image-description {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 3;
  width: 100%;
}

.name-and-description {
  flex: 1;
}

.image-block {
  position: relative;
  width: 200px;
  height: 250px;
  background-color: white;
  box-shadow: 0px 6px 14px 0px #cececeba;
  border-radius: 10px;
  border: 1px solid #adb5bd;
  margin-bottom: 20px;
}

.backdrop {
  width: 100%;
  height: 200px;
  position: relative;
  /* object-fit: contain; */
}

.image {
  width: 200px;
  height: 100%;
}

.edit-media-background {
  position: absolute;
  right: 10px;
  top: 10px;
  -webkit-transition: 0.5s;
  border-radius: 50%;

  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 2px 10px 0px #31303075;
  transition: 0.5s;
  font-size: 20px;
  color: #737373;
}

.edit-media-background:hover {
  cursor: pointer;
  background: #636363;
  color: white;
}

.checkbox-block {
  margin-bottom: 20px;
  white-space: nowrap;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inputs-section {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.body-padding {
  padding: 20px;
}

.auto-width {
  min-width: auto;
}

.media-popup {
  width: 900px !important;
}

.movie-search {
  min-height: 150px;
  max-height: 300px;
  overflow: auto;
  width: 100%;
  background: white;
  position: absolute;
  top: 40px;
  border: 1px solid #ced4da;
  box-shadow: 0px 8px 11px 0px #9a9898;
  left: 0;
  border-radius: 5px;
  padding: 5px 7px;
}

.found-movie {
  margin-top: 10px;
  display: flex;
  width: 100%;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(201, 201, 201, 0.164);
  transition: 0.3s;
  border-radius: 5px;
}

.found-cast {
  margin-top: 10px;
  display: flex;
  height: 80px;
  width: 100%;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(201, 201, 201, 0.164);
  transition: 0.3s;
  border-radius: 5px;
}

.found-cast h1 {
  font-size: 17px;
  font-weight: bold;
}

.found-cast h4 {
  margin-top: 6px;
  font-size: 15px;
  color: #636363;
}

.found-movie:hover {
  background-color: rgba(201, 201, 201, 0.39);
  cursor: pointer;
}

.found-movie h1 {
  font-size: 17px;
  font-weight: bold;
}

.found-movie h4 {
  font-size: 15px;
  color: #636363;
}

.searching-movie-image {
  width: 100px;
  object-fit: cover;
}

@media (max-width: 560px) {
  .image-block {
    margin-right: 20px;
  }
}

.media-collection-component {
  width: 100%;
  height: 130px;
  margin-bottom: 15px;
  border-radius: 5px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: rgba(201, 201, 201, 0.164);
}

.media-collection-component h1 {
  font-size: 17px;
  font-weight: bold;
}

.media-collection-component h4 {
  font-size: 15px;
  color: #636363;
}

.media-collection-component:hover .res-del {
  opacity: 1;
}

.res-del {
  right: 5px;
  position: absolute;
  color: #666666;
  transition: 0.5s;
  opacity: 0;
  font-size: 25px;
}

.res-del:hover {
  color: #a14541;
  cursor: pointer;
}

.list-is-empty {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-media-information {
  display: flex;
  align-items: center;
}

.transcoding-status-style {
  width: 100%;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
  border: 1px solid transparent;
  color: white;
  cursor: pointer;
  transition: 0.2s;
}

.transcoding-status-style:hover {
  filter: brightness(90%);
}

.enable-disable-block {
  padding: 15px;
  padding-left: 25px;
}

.movie-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: max-content;
  text-align: start;
}

.movie-info img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.movie-info-container {
  margin-left: 10px;
}

.movie-info-container h3 {
  font-size: 16px;
}

.note-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
