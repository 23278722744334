.totals {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-gap: 1rem;
}

.total {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 20px;
    background: rgb(227, 242, 253);
    background: linear-gradient(
        90deg,
        rgba(227, 242, 253, 1) 0%,
        rgba(187, 222, 251, 1) 50%,
        rgba(144, 202, 249, 0.8730085784313726) 100%
    );
    border-radius: 5px;

    .info-block {
        height: 100%;
        // background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
            font-size: 16px;
            color: rgb(53, 53, 53);
        }

        .count {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .icon-block {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: blue;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .row {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}
