.container {
    .tab {
        display: grid;
        grid-gap: 0px 20px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .images-list {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        gap: 0px 30px;
        margin-bottom: 20px;
    }
}
