.loading-bg {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: preloadAnim;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
}

@keyframes preloadAnim {
    0% {
        background-position: -769px 0;
    }
    100% {
        background-position: 769px 0;
    }
}

.skin-loading {
    height: 200px;
    padding: 1rem;
}

.skin-loading-text {
    width: 200px;
    height: 15px;
    background-color: #ada8a847;
}

.skin-loading-button {
    margin-top: 10px;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: #ada8a847;
}

.skin-loading-block {
    padding: 1rem;
    width: 100%;
    height: 80%;
    background-color: #ada8a847;
}

.skin-loading-input {
    margin-top: 10px;
    height: 40px;
    background-color: #ada8a847;
}

.skin-loading-mini-block {
    width: 30px;
    height: 30px;
    background-color: #ada8a847;
}

.skin-loading-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;
}

.server-round-loading {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ada8a847;
    box-shadow: 4px 9px 16px 4px #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
}

.server-loading {
    width: auto;
    max-width: 600px;
    height: max-content;
    border-radius: 10px;
    box-shadow: 2px 1px 7px 3px #ccc;
}

.loading--text {
    font-size: 15px;
    color: #c3c3c3;
}

.table-loading {
    width: 100%;
    height: 33px;
    margin-bottom: 10px;
    background-color: #ffffff80;
}

.loading-big-round {
    width: 150px !important;
    height: 150px !important;
}

@media (max-width: 500px) {
    .server-loading {
        width: auto;
        max-width: auto;
    }
    .server-round-loading {
        width: 60px;
        height: 60px;
    }

    .loading-big-round {
        width: 120px !important;
        height: 120px !important;
    }
}

.loading-section {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
