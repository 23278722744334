.tariff {
    color: rgb(163, 163, 163);
    display: flex;
    gap: 5px;
}

.balance {
    svg {
        width: 13px;
    }
}
