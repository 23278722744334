.player {
    width: max-content;
    .video-settings {
        display: flex;
        justify-content: center;
        gap: 30px;

        min-height: 350px;

        .video {
            position: relative;
            width: 600px;
            height: 350px;
        }

        .settings {
            max-width: 300px;
            height: 100%;
        }
    }

    .tabs {
        margin-top: 20px;
        max-width: 950px;
        overflow-y: auto;
    }

    .episodes-list {
        width: 950px;
        overflow-y: auto;
        display: flex;
        gap: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
            width: 1px;
            height: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            background: #bdbdbd;
        }

        .episode-name {
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            gap: 10px;
            cursor: pointer;
            .episode {
                width: 120px;
                height: 140px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                p {
                    width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                }
            }
        }
    }
}

.radio-groups {
    width: 300px;
    max-height: 95px;
    overflow: auto;
    padding: 5px 0;
}
