.watched-block {
    margin-top: 30px;
    // display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    gap: 30px;
    width: 100%;

    .movies-block {
        // width: 770px;
        background-color: white;
        box-shadow: 0px 0px 7px 0px #69656526;
        overflow: hidden;
        padding: 20px;
        border-radius: 10px;

        .container {
            display: flex;
            justify-content: space-between;
            gap: 10px;
        }
    }
}

.movies-list {
    width: calc(100% - 260px);

    .movie {
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.201);

        .name {
            width: 220px;
        }

        .type {
            width: 100px;
        }
    }
}

.most-watched {
    width: 240px;
    // width: 100%;
    border-radius: 5px;

    position: relative;
    cursor: pointer;

    img {
        width: 100%;
        border-radius: 5px;
    }

    &:hover {
        .container {
            opacity: 1;
        }
    }

    .container {
        transition: 0.2s;

        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.742);
        width: 100%;
        height: 100%;

        color: black;

        color: white;

        padding: 20px;
        border-radius: 5px;

        .title {
            font-size: 16px;
            font-weight: bold;
            color: white;
        }

        p {
            color: white;
        }
    }
}

.now-watching-block {
    margin-top: 30px;
    // width: 100%;
    // padding: 12px;
    // min-width: 350px;
    // width: 30%;
    // // width: 350px;
    // // height: 430px;
    // background-color: white;
    // box-shadow: 0px 0px 7px 0px #69656526;
    // overflow: hidden;
    // padding: 20px;
    // border-radius: 5px;
}

.title {
    font-size: 18px;
    // border-bottom: 1px solid rgba(172, 172, 172, 0.231);
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.now-watching {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.201);



    .type {
        width: 100px;
    }
}

.now-watched-list {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.now-watch-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 30%;
    // max-height: 70px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.201);


}

.card-content {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex: 1 0;

}

.card-image {
    width: 55px;
    height: 55px;
    border: .1px solid transparent;
    border-radius: 50%;

    &>img {
        width: 100%;
        max-height: 55px;
        height: auto;
        object-fit: fill;
        border-radius: 50%;


    }
}

.name {
    // width: 220px;
    width: 50%;
    overflow: hidden;
}

.empty-block {
    width: 100%;
    height: 335px;
    display: flex;
    justify-content: center;
    align-items: center;
}