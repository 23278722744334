.catch-up-block {
    position: relative;
}
.catch-up-is-disibled {
    position: absolute;
    top: 0;
    left: 0;
    width: 475px;
    height: 200px;
    margin-left: -10px;
    margin-top: -10px;
    z-index: 1;
    background-color: #fffbe6b0;
    color: #9f6808;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
