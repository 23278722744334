.page {
    width: 1100px;
    margin: 0px auto;

    h2 {
        font-size: 20px;
    }
}

.chat-container {
    margin-top: 20px;
    width: 100%;
    height: 600px;
    border: 1px solid #66666617;
    display: grid;
    grid-template-columns: 23% 52% 25%;
    // overflow: hidden;
}

.two-section {
    grid-template-columns: 250px calc(100% - 250px);
}

.select-chat {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        padding: 10px 20px;
        border-radius: 50px;
        background-color: #efefef;
        font-size: 13px;
    }
}
