.page {
    width: 100%;
    // height: 100%;
    margin-top: 20px;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 5px;
        text-transform: capitalize;

        .back-button {
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            border: 1px solid #9c9c9c;
            color: #9c9c9c;
            transition: 0.2s;

            &:hover {
                cursor: pointer;
                background-color: #9c9c9c;
                color: white;
            }
        }

        .routes {
            font-size: 15px;

            span {
                color: rgb(156, 156, 156);
            }

            .active {
                font-weight: 400;
                color: rgb(34, 34, 34);
            }
        }
    }
}