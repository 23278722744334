.reset-password {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;


}

.back {
    padding: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }



}

.reset-form-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    // min-height: 400px;
    background-color: rgba(235, 234, 234, 0.258);
    padding: 40px;
    border-radius: 10px;


    h2 {
        margin-bottom: 30px;
        font-size: 25px;
        font-weight: bold;
        text-align: center;

    }


}

.verify-input {
    width: 50px;
    height: 50px;
    text-align: center;
    margin: 20px;

    &:not(:first-child) {
        margin-left: 10px;
    }

}

.reset-form-button {
    text-align: center;
    width: 100%;
}

.back-step {
    margin-top: 20px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.language-selector {
    width: 90px;
    position: absolute;
    top: 7.5px;
    right: 7.5px;
}