@import url(../../../../Antd.scss);

.usage {
    // background: red;
    display: flex;
    align-items: center;
    gap: 10px;
}

.usage-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 227px;
    // height: 60px;
    // flex-shrink: 0;
    border-radius: 12px;
    background: #EDF1F8;
    padding: 4px;
    cursor: pointer;
}

.usage-card-info {

    display: flex;
    flex-direction: column;
}

.label {

    display: inline-block;
    margin: 0;
    color: #1F1F1F;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.capacity {
    display: inline-block;
    margin-top: 4px;
    color: #404040;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.percent-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 65px;
    padding: 6px;
    border-radius: 50%;

    &>span {
        color: #242331;
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }


}

.upgrade-button {
    display: flex;
    // width: 235px;
    height: min-content;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    padding: 12px 30px;
    background: var(--main-active-color);
    margin-left: 10px;
    background: radial-gradient(57.66% 57.97% at 49.79% 56%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 116, 157, 0.20) 100%), radial-gradient(53.83% 53.83% at 47.02% 50%, rgba(221, 221, 221, 0.00) 0%, rgba(0, 116, 157, 0.20) 100%), linear-gradient(148deg, rgba(33, 179, 232, 0.80) 25.25%, var(--main-active-color) 103.83%);
    box-shadow: 1px -1px 1px 0px rgba(8, 12, 13, 0.17) inset, 1px 1px 0px 1px rgba(255, 255, 255, 0.32) inset;
    cursor: pointer;
    animation: puls 3.5s infinite;

    &>span {
        color: #FFF;
        text-shadow: 0px 1px 1.9px rgba(15, 15, 15, 0.22);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

@keyframes puls {

    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1.03);
        box-shadow: 0 0 8px 18px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }


}