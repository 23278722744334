.path-drawer {
    background-color: red;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .container {
        width: 100%;
        height: 75%;
        position: relative;

        .body {
            width: 100%;
            height: 100%;
            overflow: auto;

            .paths-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: start;
                gap: 10px;
            }
        }
    }
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fefefed2;
}

.file-folder {
    width: 48%;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0px 5px;
    border-radius: 5px;
    transition: 0.2s;
    border: 1px solid transparent;

    img {
        width: 20px;
    }

    p {
        width: calc(100% - 70px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: rgba(128, 128, 128, 0.125);
    }
}

.stream-path {
    font-size: 12px;
    color: gray;
    margin-top: 0px;
}
