* {
    outline: none !important;
}

.dropdown-menu {
    display: block;
}

div.dataTables_filter input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1.5px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.dataTables_filter input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #007aff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    font-weight: 600;
    border: 1px solid #0585ff;
    background-color: #0585ff;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #fff),
        color-stop(100%, #dcdcdc)
    );
    background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
    background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
    background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
    background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
    background: linear-gradient(to bottom, #0585ff 0%, #0585ff8c 100%);
}
td img {
    height: 30px;
}

/* ColorPicker */
.twitter-picker {
    width: 100% !important;
    border: 1.5px solid rgb(212, 218, 223) !important;
    box-shadow: none !important;
}

/* Bootstrap 4 */
fieldset {
    width: 100%;
    min-width: 0;
    padding: 2%;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1.5px solid #d4dadf;
    background: #f8f8f838;
}
legend {
    display: block;
    width: auto;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

/* Turn Off Number Input Spinners */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* button:disabled {
    border: 1px solid #d7d5d5 !important;
    background-color: #dedede53 !important;
    color: #c0bebe !important;
    cursor: no-drop !important;
} */

.spinner-grow.text-primary {
    position: absolute;
    left: 33%;
}

.dropdown-item {
    font-size: 1rem;
}

/* Tab */
li.react-tabs__tab:focus {
    box-shadow: none;
    border-color: hsl(0, 0%, 67%);
    outline: none;
}
