.header {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.setup-filters-section {
    display: flex;
    align-items: center;
    gap: 20px;
}
