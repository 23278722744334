.select-device {
    label {
        margin-bottom: 10px;
    }
    padding-bottom: 20px;
    border-bottom: 1px solid #ced0d1;
}

.container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
}

.send-message {
    width: 35%;
    height: max-content;
    padding: 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 7px 0px #69656526;

    h4 {
        font-size: 20px;
        margin-bottom: 10px;
        color: grey;
    }
}

.history-table {
    width: 65%;
    padding: 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 7px 0px #69656526;

    h4 {
        font-size: 20px;
        margin-bottom: 10px;
        color: grey;
    }
}

.select-device-message {
    position: absolute;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d48806;
    background: #fdfdfdc1;
    z-index: 10;
}
