.quick-messages {
    width: 100%;
    height: 600px;

    .add-quick-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0px 10px;
        border-bottom: 1px solid #e7e8e7;
        p {
            margin: 0;
            font-size: 16px;
        }
    }

    .list {
        height: calc(100% - 55px);
        overflow-y: scroll;
        gap: 10px;
        padding: 10px;

        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #ddd;
        }
        &::-webkit-scrollbar-thumb {
            background: #bdbdbd;
        }
    }
}

.quick-message {
    position: relative;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
    transition: 0.2s;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;

    .text {
        width: 95%;
    }

    .menu {
        position: absolute;
        top: 5px;
        right: 5px;
        opacity: 0;
        cursor: pointer;
        transition: 0.2s;
        padding: 5px;
        border-radius: 5px;
        background-color: rgba(128, 128, 128, 0.428);
    }

    // &:hover {
    //     color: rgb(222, 222, 222);
    // }

    &:hover .menu {
        opacity: 1;
    }
}
