.container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

}

.age-date {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 5fr;
}